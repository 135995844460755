/**@format */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "./main-view.scss";
import CameraSizer from "./pages/camera-sizer";
import { ChassisConfiguration } from "./pages/chassis-config";
import { P3AlertPopup, P3LoadingBar, P3StatusBarStatus } from "./../components/status-bar/status-bar"
import Summary from "./pages/summary";
// import { useKeycloak } from "@react-keycloak/web";
// import { createHttpLink, useMutation } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { LOAD_USERS } from "../graphql/queries";
import { SmartNVRPage } from "./pages/smart-nvr";
import { MarketoPage } from "./pages/marketo-page";
import * as ExcelJS from 'exceljs/dist/exceljs';
import { saveAs } from "file-saver";

const defaultSizer = {
	number_of_cameras: 1, frames_per_second: 30, retention_days: 30, resolution: "2288x1712 (4 MP)",
	compression: "H.264", scene_activity: '50', compression_ratio: "30", recording_profile: "100"
};

/**
 * Sizer Tool
 */

 const getCompValues=(bandWidth,diskSpace)=>{
    let isGB = diskSpace > 0 && diskSpace < 1;
    let isKB = bandWidth > 0 && bandWidth < 1;
	let diskLabel = isGB?"(GB)":"(TB)";
    let diskValue = isGB?(diskSpace*1000):diskSpace;
    let bandLabel = isKB?"(KBit/s)":"(Mbit/s)";
    let bandValue = isKB?(bandWidth*1000):bandWidth;
    return {diskLabel,diskValue,bandLabel,bandValue};
}

export function MainView() {
	// const { keycloak } = useKeycloak();
	const { error, loading, data } = useQuery(LOAD_USERS, {
		context: {
			headers: {
				// "authorization": "Bearer " + keycloak.token,
				"Content-Type": "application/json"
			}
		},
		variables: {
			CameraInputData: null
		}
	});
	const [inputData, setCollection] = useState([]);
	const [currentStep, setCurrentStep] = useState(0);
	const [currentError, setCurrentError] = useState(error);
	// const { nvrData } = useQuery(LOAD_NVR,{
	// 	variables: {
	// 		total_bandwidth_mbps : band, total_capacity : disk
	// }});
	//const [setChassis, response] = useMutation(SET_CHASSIS_MUTATION);
	const changeStep = (step) => {
		setCurrentStep(currentStep + step);
	}

	useEffect(() => {
		if (data) {
			setCollection(data);
		}
	}, [data]);
	// useEffect(() => {
	// 	if (keycloak.token && error) {
	// 		setCurrentError(error);
	// 	}
	// }, [error]);
	if (loading) return <P3LoadingBar
							statusIcon={P3StatusBarStatus.LOADING}
							message="Loading"
						/>;
	if (currentError) {
		return <P3AlertPopup
			statusIcon={P3StatusBarStatus.FAILED}
			message={currentError}
			onAlertPopupClose={() => setCurrentError(false)}
		/>;
	}


	// let query = "query IntrospectionQuery{query{\n  camera_resolutions{\n    description\n    name\n  }\n  compression_ratios{\n    description\n    name\n    value\n  }\n  compressions{\n    compressions\n    description\n  }\n  recording_profiles{\n    description\n    name\n    value\n  }\n  scene_activities{\n    description\n    name\n    value\n  }\n  video_recoder_chassis{\n    description\n    name\n  }\n  video_recorder_models{\n    description\n    name\n  }\n  video_recorder_sizes{\n    description\n    name\n  }\n  \n}\n}";
	// let subgraphEndPoint = 'http://localhost:9090/v1/graphql';
	// const link = from([
	// 	errorLink,
	// 	new HttpLink({ uri: "http://localhost:9090/v1/graphql", fetchOptions: {
	// 	  mode: 'no-cors',
	// 	} }),
	//   ]);
	// //let link = createHttpLink({ uri: subgraphEndPoint });
	// let cache = new InMemoryCache({addTypename: false});
	// let variables = {}
	// let apolloClient = new ApolloClient({ link, cache });
	// let gqlQuery = gql(query);
	// apolloClient.query({query: gqlQuery,variables,fetchPolicy: 'no-cache'}).then(console.log);
	// if (keycloak.token) {
		return (
			<MainPage data={inputData} currentStep={currentStep} changeStep={changeStep}
			// keycloakToken={keycloak.token}
			/>
		)
	// } else {
	// 	return (
	// 		<P3LoadingBar
	// 			statusIcon={P3StatusBarStatus.LOADING}
	// 			message="Loading"
	// 		/>
	// 	)
	// }
}

export class MainPage extends React.Component {
	static propTypes = {
		onClose: PropTypes.func,
		onStatusAlert: PropTypes.func
	};
	constructor(props) {
		super(props);
		let cameras = [JSON.parse(JSON.stringify(defaultSizer))];
		this.state = {
			isProgress: false,
			isAlertPopup: false,
			currentStep: props.currentStep,
			diskSpace: 0,
			bandWidth: 0,
			camDatas: [],
			isProjectSubmit: false,
			selectedNvr: 1,
			nvrIndex: 0,
			smartNvrIndex: 0,
			selectedNetwork: "",
			nvr: [],
			statusIcon:P3StatusBarStatus.FAILED,
			projectName: "Project_",// + Date.now(),
			defaultActivePanelIndices: [0],
			networkTypes: [],
			summaryItems: [],
			cameras,
			isValid: this.isValidInputs(cameras),
			chassisItems: [],
            isSmartNvr: false,
            summaryData: []
		};
	}

	onPrevHandler = () => {
		this.props.changeStep(-1);
		//this.updateSummary();
	}
	onNextHandler = () => {
		if (this.state.isProjectSubmit) {
			this.props.changeStep(-3);
			this.setState({
				isProjectSubmit: false,
				cameras: [JSON.parse(JSON.stringify(defaultSizer))],
				camDatas: [],
				projectName: "Project_",
				selectedNvr: 1,
				nvrIndex: 0,
				smartNvrIndex: 0,
			});
		} else {
			this.props.changeStep(1);
		}
	}

    downloadExcel=(camCount,computeValues)=>{
        let downloadData = [...this.state.summaryData];
        let requiremntData = [];
        requiremntData.push({
            "key":"Camera Count",
            "value": camCount.toString()
        });
        requiremntData.push({
            "key": "Total Bandwidth Required (Mbit/s)",
            "value": Math.round(computeValues.bandValue).toString()
        });
        requiremntData.push({
            "key": "Total Storage Required (TB)",
            "value": Math.round(computeValues.diskValue).toString()
        })
        downloadData.map(el=>{
            if(el.key=="Number of NVRs"){
                el.value = el.value.toString();
            }
        })
        // const ws = utils.json_to_sheet(downloadData);
        // ws['!cols'] = [{ width: 30 }, { width: 40 } ]; //set col. widths
        // let Heading = [['ITEM', 'RECOMMENDATIONS']];
        // XLSX.utils.sheet_add_aoa(ws, Heading);
        // //XLSX.utils.sheet_add_json(ws, arr, { origin: 'A3', skipHeader: true });
        // const wb = utils.book_new();
        // utils.book_append_sheet(wb, ws, "Data", { origin: 'A3', skipHeader: true });
        // //writeFileXLSX(wb, "Quantum_Sizer_Summary.xlsx");
        this.downloadUsingExcelJS(downloadData, requiremntData);
    }

    downloadUsingExcelJS(data, require){
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet('Summary Data');
        sheet.mergeCells("A1:B1");
        //sheet.getRow(1).values = ["Quantum NVR Configurator Summary",];
        sheet.getCell('A1').value = {'richText':[ {'font': {'bold': true,'size': 16},'text': 'Quantum NVR Configurator Summary'},]};
        sheet.getCell('A1').alignment = { horizontal: 'center' };
        // data.map((el,index)=>{
        //     sheet.getCell("A4").fill={
        //         type: 'pattern',
        //         pattern:'solid',
        //         fgColor:{ argb:'DCE6F1' }
        //     }
        // });
        sheet.getRow(3).values = ["ITEM","RECOMMENDATIONS"];
        sheet.getCell("A3").fill = {
            type: 'pattern',
            pattern:"solid",
            fgColor:{ argb:"0070C0"}
        }
        sheet.getCell("A3").font = {
            color: { argb: "FFFFFF" },
            bold: true,
          };
        sheet.getCell('A3').border = {
            top: {style:'thin', color: {argb:'FFFFFF'}},
            left: {style:'thin', color: {argb:'FFFFFF'}},
            bottom: {style:'thin', color: {argb:'FFFFFF'}},
            right: {style:'thin', color: {argb:'FFFFFF'}}
          };
          sheet.getCell('B3').border = {
            top: {style:'thin', color: {argb:'FFFFFF'}},
            left: {style:'thin', color: {argb:'FFFFFF'}},
            bottom: {style:'thin', color: {argb:'FFFFFF'}},
            right: {style:'thin', color: {argb:'FFFFFF'}}
          };
        sheet.getCell("B3").fill = {
            type: 'pattern',
            pattern:"solid",
            fgColor:{ argb:"0070C0"}
        }
        sheet.getCell("B3").font = {
            color: { argb: "FFFFFF" },
            bold: true,
          };
        sheet.getCell("A15").fill = {
            type: 'pattern',
            pattern:"solid",
            fgColor:{ argb:"7030A0"}
        }
        sheet.getCell("B15").fill = {
            type: 'pattern',
            pattern:"solid",
            fgColor:{ argb:"7030A0"}
        }
        data.map((el,index)=>{
            sheet.getRow(index+4).values = [el.key,el.value];
            sheet.getCell('A'+(index+4)).fill = {
                type: 'pattern',
                pattern:"solid",
                fgColor:{ argb:"DCE6F1"}
            }
            sheet.getCell('A'+(index+4)).border = {
                top: {style:'thin', color: {argb:'FFFFFF'}},
                left: {style:'thin', color: {argb:'FFFFFF'}},
                bottom: {style:'thin', color: {argb:'FFFFFF'}},
                right: {style:'thin', color: {argb:'FFFFFF'}}
              };
            sheet.getCell('B'+(index+4)).fill = {
                type: 'pattern',
                pattern:"solid",
                fgColor:{ argb:"DCE6F1"}
            }
            sheet.getCell('B'+(index+4)).border = {
                top: {style:'thin', color: {argb:'FFFFFF'}},
                left: {style:'thin', color: {argb:'FFFFFF'}},
                bottom: {style:'thin', color: {argb:'FFFFFF'}},
                right: {style:'thin', color: {argb:'FFFFFF'}}
              };
        })
        sheet.columns = [
            { width: 40 }, { width: 40 }
          ];
        sheet.getCell("A15").value = {'richText':[ {'font': {'bold': true,'color': {'argb': 'FFFFFF'}},'text': 'REQUIREMENTS'},]};
        sheet.getCell("A15").font = {
            color: { argb: "FFFFFF" },
            bold: true,
          };
        const date = new Date();
        sheet.getCell("A20").value = {'richText':[ {'font': {'bold': false},'text': 'Generated at:'+date.toUTCString()},]};
        sheet.getCell("A20").fill = {
            type: 'pattern',
            pattern:"solid",
            fgColor:{ argb:"E4DFEC"}
        }
        require.map((el,index)=>{
            sheet.getRow(index+16).values = [el.key,el.value];
            sheet.getCell('A'+(index+16)).fill = {
                type: 'pattern',
                pattern:"solid",
                fgColor:{ argb:"E4DFEC"}
            }
            sheet.getCell('A'+(index+16)).border = {
                top: {style:'thin', color: {argb:'FFFFFF'}},
                left: {style:'thin', color: {argb:'FFFFFF'}},
                bottom: {style:'thin', color: {argb:'FFFFFF'}},
                right: {style:'thin', color: {argb:'FFFFFF'}}
              };
            sheet.getCell('B'+(index+16)).fill = {
                type: 'pattern',
                pattern:"solid",
                fgColor:{ argb:"E4DFEC"}
            }
            sheet.getCell('B'+(index+16)).border = {
                top: {style:'thin', color: {argb:'FFFFFF'}},
                left: {style:'thin', color: {argb:'FFFFFF'}},
                bottom: {style:'thin', color: {argb:'FFFFFF'}},
                right: {style:'thin', color: {argb:'FFFFFF'}}
              };
        })
        workbook.xlsx.writeBuffer().then(function(buffer) {
            // done
            //console.log(buffer);
        
            const blob = new Blob([buffer], { type: "applicationi/xlsx" });
            saveAs(blob, "Quantum NVR Configurator Summary.xlsx");
          });
    }

	updateValue = (obj) => {
		this.setState({ ...obj });
	}

	addPage = () => {
		let cameras = this.state.cameras;
		let defaultActivePanelIndices = this.state.defaultActivePanelIndices;
		defaultActivePanelIndices.push(cameras.length);
		cameras.push(JSON.parse(JSON.stringify(defaultSizer)));
		this.setState({ cameras, defaultActivePanelIndices });
	}

	modifyPage = (item, value, index, data = null) => {
		if (item === "disk") {
			let camDatas = this.state.camDatas;
			camDatas[index] = value;
			this.setState({ camDatas });
			return;
		}
		let cameras = this.state.cameras;
		cameras[index][item] = value;
		let isValid = this.isValidInputs(cameras);

		this.setState({ cameras, isValid });
	}

	isValidInputs = (cameras) => {
		let isValid = cameras.some((item) => {
			return !Number(item.retention_days) > 0 || !Number(item.number_of_cameras) > 0 || !Number(item.frames_per_second) > 0 || item.compression === "" ||
				item.compression_ratio === "" || item.recording_profile === "" || item.resolution === "" || item.scene_activity === ""
		})
		return !isValid;
	}

	getCalculatedValue = (args) => {
		//const {cameras,bandWidth} = args
		return { bandWidth: 300 + Math.ceil(Math.random() * 400), diskSpace: 600 + Math.ceil(Math.random() * 400) };
	}

	onDelete = (index) => {
		let cameras = this.state.cameras;
		cameras.splice(index, 1);
		let isValid = this.isValidInputs(cameras);
		let camDatas = this.state.camDatas;
		camDatas.splice(index, 1);
		this.setState({ cameras, isValid, camDatas });
	}

	onChange = (param, value) => {
		this.setState({ [param]: value });
	}

	changeActiveIndex = (defaultActivePanelIndices) => {
		this.setState({ defaultActivePanelIndices });
	}

	getnumber_of_cameras = () => {
		return this.state.isValid && this.state.cameras.reduce((a, b) => a + ((b["number_of_cameras"]) || 0), 0) > 0
	}

	componentDidUpdate(prevProps, _prevState) {
		if (this.props.data !== prevProps.data) {
			this.setState({
				chassisItems: this.props.data.getChassisItems
			});
		}
		if (this.props.currentStep !== prevProps.currentStep) {
			this.setState({
				currentStep: this.props.currentStep
			});
		}
	}
    
    componentDidMount(){
        const currentURL = window.location.href;
        let arr = currentURL.split('?');
        if(arr.length>1){
            window.location.href = arr[0];
        }
    }

	sum = (arr, param) => {
		return arr.reduce((a, b) => a + ((b[param]) || 0), 0)
	}

	getNvr = (nvr, setDefault = true) => {
        let ele;
        nvr.map(o=>{
            if(o.product_class==="nvr"){
                ele = o.children;
            }});
		if(ele[0].num_nodes===ele[1].num_nodes){
			let models = ele.filter(el=>el.model==="VS4260-NVR");
			if(models.length==1){
				ele = ele.filter(el=>el.model!=="VS4260-NVR");
			}
		}
		if(ele[0].drive_description==ele[1].drive_description && ele[0].recommendation_details==ele[1].recommendation_details){
				ele.shift();
			}
		if(ele[0]===ele[1]){
			ele.shift();
		}
		setDefault && nvr.map((item, index) => {
			item.children.map((child, ind) => {
				child.isActive = (ind === ((index === 0)?this.state.smartNvrIndex:this.state.nvrIndex));
				return child;
			})
			item.isActive = (index === 0);
			return item;
		});
		this.setState({ nvr });
	}

	//submitProject = (projectName, callBack) => {
    computeQuoteInput = () => {
        let selectedNvr = this.state.selectedNvr;
        if(this.state.nvr.length===1){
            selectedNvr=0;
        }
		let bandWidth = Math.round(this.sum(this.state.camDatas, "bandwidth"));
		let diskSpace = Math.round(this.sum(this.state.camDatas, "disk_space"));
        let selectedNvrRecommendationType = this.state.nvr.find(el=>el.product_class=="nvr").children[this.state.nvrIndex].recommendation_type;
        let nvr = this.state.nvr[selectedNvr].children.find(el=>el.recommendation_type==selectedNvrRecommendationType);
		//let nvr = this.state.nvr[this.state.selectedNvr].children[(this.state.selectedNvr === 0 ? this.state.smartNvrIndex : this.state.nvrIndex)]
		let nvrQuote = {
			"selected_recorder_id": nvr.uuid,
			"selected_nic": this.state.networkTypes ? this.state.selectedNetwork : "",
			"num_nodes": nvr.num_nodes,
			"recommendation_type": nvr.recommendation_type
		}
		let chosen_input = JSON.stringify({ cameras: this.state.cameras, bandWidth, diskSpace, nvrQuote });
		nvrQuote.chosen_input = chosen_input;
		// this.setState({ isProjectSubmit: true }, ()=>callBack(nvrQuote));
		return nvrQuote;
	}

	summaryError = (errorMessage) =>{
		this.setState({isAlertPopup:true, errorMessage, isProjectSubmit: false});
	}

	openAlert = (errorMessage,statusIcon) =>{
		this.setState({isAlertPopup:true, errorMessage, statusIcon});
	}

	render() {
		const bandWidth = this.sum(this.state.camDatas, "bandwidth");
		const diskSpace = this.sum(this.state.camDatas, "disk_space");
        const noOfCameras = this.state.cameras.reduce((a, b) => a + ((Number(b["number_of_cameras"])) || 0), 0);
		let computeValues = getCompValues(bandWidth, diskSpace);
		let enableNext = this.getnumber_of_cameras() && bandWidth > 0 && diskSpace > 0;
		let stepsTitle = [
			{ label: "Calculate Bandwidth and Storage" },
			{ label: "NVR Recommendations" },
			{ label: "Smart NVR Selection" },
			{ label: "Summary" },
            { label: "Request Quote" }]
		return (
			<div className="container w-100 h-100">
				<ul className="horizontalStep">
					{stepsTitle.map((step, index) => {
						return (
							<StepInfo
								step={step}
								key={index}
								index={index + 1}
								currentStep={this.state.currentStep}
								maxSteps={stepsTitle.length}
							/>
						);
					})}
				</ul>
				<div className={ this.state.currentStep > 0 ? "other-page" : "content-page"}>
					{this.state.currentStep === 0 && <CameraSizer
						isValid={this.state.isValid}
						data={this.props.data}
						// keycloak={this.props.keycloak}
						bandWidth={bandWidth}
						diskSpace={diskSpace}
						changeActiveIndex={this.changeActiveIndex}
						defaultActivePanelIndices={this.state.defaultActivePanelIndices}
						onDelete={this.onDelete}
						modifyPage={this.modifyPage}
						addPage={this.addPage}
						openAlert={this.openAlert}
						cameras={this.state.cameras} />}
					{this.state.currentStep === 1 && <ChassisConfiguration
						networkTypes={this.state.networkTypes}
						modifyPage={this.modifyPage}
						updateValue={this.updateValue}
						getNvr={this.getNvr}
						// keycloakToken={this.props.keycloakToken}
						nvrIndex={this.state.nvrIndex}
						smartNvrIndex={this.state.smartNvrIndex}
						bandWidth={bandWidth}
						diskSpace={diskSpace}
						openAlert={this.openAlert}
						selectedNetwork={this.state.selectedNetwork}
						nvr={this.state.nvr}
						selectedNvr={this.state.selectedNvr}
						onChange={this.onChange} />}
					{this.state.currentStep === 2 && <SmartNVRPage
						nvr={this.state.nvr}
						nvrIndex={this.state.nvrIndex}
						smartNvrIndex={this.state.smartNvrIndex}
						updateValue={this.updateValue}
                        isSmartNvr={this.state.isSmartNvr}
						 />}
					{this.state.currentStep === 3 && <Summary
						projectName={this.state.projectName}
						updateValue={this.updateValue}
						// keycloakToken={this.props.keycloakToken}
						quoteInput={this.computeQuoteInput()}
						openAlert={this.openAlert}
						isProjectSubmit={this.state.isProjectSubmit}
						summaryError={this.summaryError}
						summaryItems={this.state.summaryItems}
						nvr={this.state.nvr}
						selectedNvr={this.state.selectedNvr} 
						nvrIndex={this.state.nvrIndex}
						smartNvrIndex={this.state.smartNvrIndex}
                        summaryData={this.state.summaryData}/>}
                    {this.state.currentStep === 4 && <MarketoPage
                        summaryData={this.state.summaryData}
                        cameras={this.state.cameras}
						/>}
				</div>
				{this.state.currentStep > 0 &&<div className="general-info">
				<div className=" title-cont-details">
                        <label className="title-label-details">{"Camera Count"}</label>
                        <label className="content-label-details content-highlight-details">{noOfCameras}</label>
                    </div>
                    <div className=" title-cont-details">
                        <label className="title-label-details">{"Total Bandwidth Required "+computeValues.bandLabel}</label>
                        <label className="content-label-details content-highlight-details">{Math.round(computeValues.bandValue) || 0}</label>
                    </div>
                    <div className=" title-cont-details">
                        <label className="title-label-details">{"Total Storage Required "+computeValues.diskLabel}</label>
                        <label className="content-label-details content-highlight-details">{Math.round(computeValues.diskValue) || 0}</label>
                    </div>
					
                </div>}
				<div className="p3-button-container">
                    {this.state.currentStep==3 && <button
						id="prevButton"
						data-auto-id="prevButton"
						className={"btn  p3-btn "}
						onClick={()=>this.downloadExcel(noOfCameras,computeValues)}
					>Download</button>}
					{this.state.currentStep > 0 && <button
						id="prevButton"
						data-auto-id="prevButton"
						className={"btn  p3-btn " + (this.state.isProjectSubmit ? "hideNext" : "")}
						onClick={this.onPrevHandler}
						disabled={
							this.state.currentStep === 0
						}
					>Back</button>}
					{!this.state.isProjectSubmit ? <button
						id="nextButton"
						disabled={!enableNext}
						data-auto-id="nextButton"
						className={"btn p3-btn " + (this.state.currentStep > 3 ? "hideNext" : "")}
						onClick={this.onNextHandler}
					>{this.state.currentStep==3?"Get Quote":"Next"}</button> :
						<button
							id="nextButton"
							data-auto-id="nextButton"
							className={"btn p3-btn "}
							onClick={this.onNextHandler}
						>Start Over Again</button>}
				</div>
				{this.state.isAlertPopup && <P3AlertPopup
					statusIcon={this.state.statusIcon}
					message={this.state.errorMessage}
					onAlertPopupClose={() => this.setState({isAlertPopup:false})}
				/>}
			</div>
		);
	}
}

export default MainView;
export const StepInfo = props => {

	const {
		index,
		step,
		currentStep,
		maxSteps
	} = props;

	// if (index <= maxSteps) {
	// 	showStepTitle = true;
	// 	if (disabledSteps.indexOf(index) !== -1 && currentStep > index) {
	// 		classNames.push("in-active");
	// 	} else {
	// 		if (index < currentStep) {
	// 			// visited views
	// 			classNames.push("visited");
	// 		} else if (index > currentStep) {
	// 			// next views
	// 			classNames.push("not-visited");
	// 		} else {
	// 			// current view
	// 			if (step.status) {
	// 				classNames.push("visited");
	// 			} else {
	// 				classNames.push("active");
	// 			}
	// 		}
	// 	}
	// }
	let activeClass = currentStep === index - 1 ? "active-circle" : "inactive-circle"
	return (
		<div className="step-parent">
			<div className="step-cont col">
				<div className={"step-circle " + activeClass}>
					{index}
				</div>
				<span className="step-desc">{step.label}</span>
			</div>
			{index < maxSteps && (
				<HorizontalStepInfoArrow />
			)}
		</div>
	);
};
export const HorizontalStepInfoArrow = ({ className }) => (
	<svg width="110" height="12" viewBox="0 0 110 12" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M110 6L100 0.226497V11.7735L110 6ZM0 7H101V5H0V7Z" fill="#97A0AF" />
	</svg>
);

export const SelectDropdown = props => {
	const { dataProvider, labelField, valueField, selectedValue, className, onChange, name, disabled=false } = props;
	if (!dataProvider) {
		return <></>;
	}
	if (selectedValue === "" && dataProvider.length) {
		onChange(dataProvider[0][valueField], name);
	}
	function onSelectChange(e) {
		onChange(e.target.value, name);
	}
	return (
		<select className={className} value={selectedValue} onChange={onSelectChange} disabled={disabled}>
			<option key={name} style={{ display: "none" }}>Select</option>
			{dataProvider.map((item, index) => { return (<option key={name + index} item={item} index={index} value={item[valueField]}>{item[labelField]}</option>) })}
		</select>
	)
}
