import React from "react";
import "./sizer-style.scss";
import { ScrollArea } from "../../components/p3-scrollBar";
import { P3LoadingBar, P3StatusBarStatus } from "../../components/status-bar/status-bar";

export function MarketoPage(props) {
    return (
        <MarketoForm {...props}></MarketoForm>
    )
}

export class MarketoForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            label:"",
        };
    }

    componentDidMount() {
        if(window !== undefined && window.MktoForms2 !== undefined){
        window.MktoForms2.loadForm("//landing.quantum.com", "561-AAR-658", 6832);
        }
        setTimeout(()=>{
            this.setState({loading:false});
            let userData = [...this.props.summaryData];
            document.getElementById("fake-btn").style.display = "unset";
            // Object.keys(this.props.cameras[0]).map(el=>{
            //     userData.push({"key":el,"value":this.props.cameras[0][el]});
            // })
            this.props.cameras.map((el,index)=>{
                userData.push({
                    "key": "camera"+index,
                    "value": el
                })
            });

            var element = document.querySelector("form[id='mktoForm_6832'] input[name='Marketing_Comments__c']");
            if(element){
                element.value = JSON.stringify(userData);
            }else{
                console.log("the form input Marketo_Sync__c is not available");
            }
        },3000);
    }

    showConfirmationPopup(){
        let Obj = {
            "first name": document.getElementById("FirstName").value,
            "last name": document.getElementById("LastName").value,
            "buisness email": document.getElementById("Email").value,
            "industry": document.getElementById("Industry").value,
            "company name": document.getElementById("Company").value,
            "title": document.getElementById("Title").value,
            "phone number": document.getElementById("Phone").value,
            "postalCode": document.getElementById("PostalCode").value,
            "country": document.getElementById("Country").value,
        }
        let formValid=true;
        let labelMissing=[];
        Object.keys(Obj).map(label=>{
            if(this.checkFormValid(label,Obj[label]) && formValid){
                formValid = true;
            }else{
                labelMissing.push(label);
                formValid = false;
            }
        });
        var insideBtnRef = document.getElementsByClassName("mktoButton");
        let privacyPolicyChecked = document.getElementsByClassName("mktoValid mktoCheckboxList")[0]
        if(formValid && privacyPolicyChecked){
            document.getElementById("popup").style.display = "block";
            setTimeout(()=>{
                insideBtnRef[0].click();
                document.getElementById("fake-btn").style.display = "none";
                //console.log("btn was clicked");
            },10000);
        }else if(!formValid){
            alert("Please enter the "+labelMissing[0]+".");
        }else if(formValid && !privacyPolicyChecked){
            alert("Please agree to the terms and conditions.");
        }
    }

    checkFormValid(label,value){
        let formValid = true;
        if(value==="" || !value){
            console.log(label+" not entered");
            formValid = false;
        }
        return formValid;
    }

    render() {
        
        return (
            <ScrollArea
                    ref={input => (this.scrollArea = input)}
                    className="p3-scrollbar-area"
                    contentClassName={
                        " p3-scrollbar-content"
                    }
                    smoothScrolling={true}
                >
                <div className="w-100 h-100 col">
                        {this.state.loading?<P3LoadingBar
                                statusIcon={P3StatusBarStatus.LOADING}
                                message="Loading"
                            />:""}
                    <div className="marketo-form-div">    
                        <form id="mktoForm_6832" target="myframe"></form>
                        <div className="fake-btn-div">
                        <button id="fake-btn" className="fake-submit-btn" onClick={()=>{this.showConfirmationPopup()}}>Submit</button>
                        </div>
                    </div>
                </div>
                <div className="popup-background" id="popup">
                    <div className="popup-div">
                        <div>Thank you for submitting the request for a quote.</div>
                        <div>Our team will be in touch with you shortly.</div>
                    </div>
                </div>
            </ScrollArea>
            
        );
    }
}