import React from "react";
import { P3RadioButton } from "../../components/form/form-item";
import { ScrollArea } from "../../components/p3-scrollBar";
import "./sizer-style.scss";

const smartNvrModelsArray = ["VS4260-NVR","VS2118-NVR","VS2208-NVR"];

export function SmartNVRPage(props) {
    return (
        <SmartPage {...props}/>
    )
}

export class SmartPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isSmartNvr: false,
            isProgress: false,
            isAlertPopup: false,
            nvr: this.props.nvr,
            nvrIndex:this.props.nvrIndex,
            smartNvrIndex:this.props.smartNvrIndex,
            selectedNvr: this.props.selectedNvr,
            networkTypes: this.props.networkTypes,
            selectedNetwork: this.props.selectedNetwork,
            allowSmartNvr: false
        };
    }

    componentDidUpdate(prevProps, _prevState) {
        if(prevProps.selectedNvr !== this.props.selectedNvr){
            this.setState({selectedNvr:this.props.selectedNvr})
        }
        if(prevProps.nvr !== this.props.nvr){
            this.setState({nvr:this.props.nvr}, ()=>this.setNvrItems(false))
        }
        if(prevProps.networkTypes !== this.props.networkTypes){
            this.setState({networkTypes:this.props.networkTypes})
        }
        if(prevProps.selectedNetwork !== this.props.selectedNetwork){
            this.setState({selectedNetwork:this.props.selectedNetwork})
        }
        if(prevProps.nvrIndex !== this.props.nvrIndex){
            this.setState({nvrIndex:this.props.nvrIndex})
        }
        if(prevProps.smartNvrIndex !== this.props.smartNvrIndex){
            this.setState({smartNvrIndex:this.props.smartNvrIndex})
        }
        if(prevProps.isSmartNvr !== this.props.isSmartNvr){
            this.setState({isSmartNvr:this.props.isSmartNvr})
        }
    }

    componentDidMount(){
        //this.setNvrItems();
        this.checkSmartNvr();
    }

    setNvrItems = (setExistingNetwork=true) =>{
        this.state.nvr.map((item,index)=>{
            return item.children.map((child, ind)=>{
                let childIndex = (index === 0)?this.state.smartNvrIndex:this.state.nvrIndex;
                child.isActive = (ind === childIndex)
                if(this.state.selectedNvr === index && ind === childIndex){
                    let networkTypes = child.nics;
                    let selectedNetwork = (this.props.selectedNetwork === "" && setExistingNetwork) ?((child.nics && child.nics.length) ? child.nics[0].display:""):this.props.selectedNetwork;
                    this.setState({networkTypes, selectedNetwork},()=>this.props.updateValue({networkTypes, selectedNetwork}));
                }
                return child;
            })
        })
    }

    setNvr = (ind) => {
        let networkIndex = ind === 0 ? this.state.nvrIndex : this.state.smartNvrIndex;
        let networkTypes = this.state.nvr[ind].children[networkIndex].nics;
        let selectedNetwork = (networkTypes && networkTypes.length)?networkTypes[0].display:this.state.selectedNetwork;
        this.props.updateValue({selectedNvr: ind, networkTypes, selectedNetwork});
    }

    checkSmartNvr = ()=>{
        //To check if a smart NVR exists corresponding to the selected standard NVR.
        let smartNvrExist=false;
        let selectedStandardNVR = this.state.nvr.find(el=>el.product_class=="nvr").children[this.state.nvrIndex];
        let smartNVR = this.state.nvr.find(el=>el.product_class=="smart_nvr");
        let selectedSmartNVR = this.state.nvr[0].children.find(el=>el.recommendation_type==selectedStandardNVR.recommendation_type);
        if(selectedSmartNVR && smartNvrModelsArray.includes(selectedStandardNVR.model) && smartNVR){
            smartNvrExist = true;
        }
        this.setState({allowSmartNvr:smartNvrExist});
    }

    render() {
        return (
            <div className="w-100 h-100 col">
                <ScrollArea
                    ref={input => (this.scrollArea = input)}
                    className="p3-scrollbar-area"
                    contentClassName={
                        " p3-scrollbar-content"
                    }
                    smoothScrolling={true}
                >
                    <div className="row ml-0 pt-4">
                        <div className="font-weight-bold smart-nvr-text">Would you like to upgrade to a Smart NVR?</div>
                        <div className="pl-0 pb-2">
                                <P3RadioButton col={0} containerClassName="text-left pl-2"  id="test" isChecked={!this.props.isSmartNvr} label="No"
                                onChange={()=>{this.props.updateValue({isSmartNvr: false});this.setNvr(1)}}/>
                        </div>
                        <div className="pl-2 pb-2">
                                <P3RadioButton col={0} containerClassName="text-left pl-2" id="test" isChecked={this.props.isSmartNvr} label="Yes"
                                onChange={()=>{this.props.updateValue({isSmartNvr: true});this.setNvr(0)}} disabled={!this.state.allowSmartNvr}/>
                        </div>
                    </div>
                    <div className="row  pl-0 pb-4">
                        A Smart NVR has Quantum Smart NVR software installed in a Standard NVR.
                    </div>
                
                    <div className="row ml-0 mr-0">
                        <table className="table table-bordered table-striped">
                            <tbody>
                                <tr>
                                    <th className="text-left" scope="col">
                                    </th>
                                    <th className={this.props.isSmartNvr?"":"selected-nvr"}>
                                        Quantum Standard NVR
                                    </th>
                                    <th className={this.props.isSmartNvr?"selected-nvr":""}>
                                        Quantum Smart NVR
                                    </th>
                                </tr>
                                <tr>
                                    <th className="text-left" scope="col">
                                        Run multiple VMS recorders in the same NVR
                                    </th>
                                    <td className={this.props.isSmartNvr?"":"selected-nvr"}>
                                        Only 1 VMS recorder per NVR
                                    </td>
                                    <td className={this.props.isSmartNvr?"selected-nvr":""}>
                                        <div className="check-mark-icon"></div>   
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-left" scope="col">
                                        Run other security applications in the same NVR
                                    </th>
                                    <td className={this.props.isSmartNvr?"":"selected-nvr"}>
                                        Needs separate servers
                                    </td>
                                    <td className={this.props.isSmartNvr?"selected-nvr":""}>
                                        <div className="check-mark-icon"></div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-left" scope="col">
                                        Advanced Monitoring
                                    </th>
                                    <td className={"icon-center "+(this.props.isSmartNvr?"":"selected-nvr")}>
                                        <div class="check-mark-icon-right"></div>
                                    </td>
                                    <td className={this.props.isSmartNvr?"selected-nvr":""}>
                                        <div className="check-mark-icon"></div>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-left" scope="col">
                                        Advanced Management
                                    </th>
                                    <td className={this.props.isSmartNvr?"":"selected-nvr"}>
                                        Basic Management
                                    </td>
                                    <td className={"icon-center "+(this.props.isSmartNvr?"selected-nvr":"")}>
                                        <div className="check-mark-icon"></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </ScrollArea>
            </div>
        )
    }
}