import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { P3DataGrid } from "../../components/data-grid/p3-data-grid";
import { P3RadioButton } from "../../components/form/form-item";
import { ScrollArea } from "../../components/p3-scrollBar";
import { P3InfoIcon, P3StatusBarStatus } from "../../components/status-bar/status-bar";
import { LOAD_NVR } from "../../graphql/queries";
import { SelectDropdown } from "../main-view";
import "./sizer-style.scss";

const groupData = (d) => {
    const merged = d.reduce((r, { product_class, product_class_display, product_class_description,...rest }) => {
        const key = `${product_class}-${product_class_display}-${product_class_description}`;
        r[key] = r[key] || { product_class, product_class_display, product_class_description, children: [] };
        r[key]["children"].push(rest)
        return r;
    }, {})
    const data = Object.values(merged).reverse();
    return data;
}

export function ChassisConfiguration(props) {
    const { bandWidth, diskSpace } = props;
    const { data } = useQuery(LOAD_NVR, {
        context: {
			headers: {
				// "authorization": "Bearer " + props.keycloakToken,
				"Content-Type": "application/json"
			}
		},
        variables: {
            total_bandwidth_mbps: bandWidth, total_capacity: diskSpace
        }
    });
    useEffect(() => {
        if (data) {
            let sizeData = data.retrieve_recommended_nvrs || {};
            let nvr = groupData(JSON.parse(JSON.stringify(sizeData)), "product_class_display", "product_class");
            props.getNvr(nvr);
        }
    }, [data]);
    return (
        <ChassisPage {...props} />
    )
}
export class ChassisPage extends React.Component {
    static propTypes = {
        onClose: PropTypes.func,
        onStatusAlert: PropTypes.func
    };
    static defaultProps = {
        networkTypes: []
    }
    constructor(props) {
        super(props);
        this.state = {
            isSmartNvr: false,
            isProgress: false,
            isAlertPopup: false,
            nvr: this.props.nvr,
            nvrIndex:this.props.nvrIndex,
            smartNvrIndex:this.props.smartNvrIndex,
            selectedNvr: this.props.selectedNvr,
            networkTypes: this.props.networkTypes,
            selectedNetwork: this.props.selectedNetwork
        };
        this.customerPhoneErrorMessage = {
            required: "Fields are required",
            minLength: "Fields should be between 2 and 50 characters",
            maxLength: "Fields should be between 2 and 50 characters"
        }
    }

    componentDidMount(){
        this.setNvrItems();
    }

    setNvrItems = (setExistingNetwork=true) =>{
        this.state.nvr.map((item,index)=>{
            return item.children.map((child, ind)=>{
                let childIndex = (index === 0)?this.state.smartNvrIndex:this.state.nvrIndex;
                child.isActive = (ind === childIndex)
                if(this.state.selectedNvr === index && ind === childIndex){
                    let networkTypes = child.nics;
                    let selectedNetwork = (this.props.selectedNetwork === "" && setExistingNetwork) ?((child.nics && child.nics.length) ? child.nics[0].display:""):this.props.selectedNetwork;
                    this.setState({networkTypes, selectedNetwork},()=>this.props.updateValue({networkTypes, selectedNetwork}));
                }
                return child;
            })
        })
        this.props.updateValue({isSmartNvr:false,selectedNvr:1});
    }

    getModifiedConfig(config){
        let arr = config.split(", ");
        arr[arr.length-1] = arr[arr.length-1].toUpperCase();
        arr.splice(2,1);
        let confString = arr.join(", ");
        return confString;
    }

    getModifiedDescription(description){
        let descripArr = description.split(", ");
        let quantity = descripArr[2].slice(1,-2);
        let drivePack = descripArr[1].split(" ");
        descripArr.unshift();
        return drivePack[0]+" "+ descripArr[descripArr.length-1]+" HDD" + ", " + "Quantity " + quantity;
    }

    onDeviceChange = (obj, index, isNvr) => {
        let dp;
        if(this.props.nvr.length>1){
            dp = this.props.nvr[1].children;
        }else{
            dp = this.props.nvr[0].children;
        }
		dp.map((item, ind) => {
			if (index === ind) {
				item.isActive = true;
			} else {
				item.isActive = false;
			}
			return item;
		});
	}

    getCols = (label = "default") => {
        return this.cols = [
            new P3ColumnData(
                "NVR Model",
                "DisplayTotalUsed"
            ).mixin({
                allowSort: false, allowFilter: false,
                width: "21.66%",
                labelFunction: (row, col, index) => (
                    <div className="row">
                        <P3RadioButton col={2} id={label} name={label} isChecked={row.isActive}/>
                        <div className="pl-3">
                            <label className={"pr-2 m-0 " + (row.model === "VS4260-NVR" ? "recommend" : "recommend")}>{row.model === "VS4260-NVR"?"High Density Model":"Standard Density Model"}</label>
                            <div className="chassis-description chassis-model">{row.model}</div>
                        </div>
                    </div>
                )
            }),
            new P3ColumnData(
                "Configuration",
                "recommendation_details"
            ).mixin({
                allowSort: false, allowFilter: false, width: "16.66%", labelFunction: (row, col, index) => (
                    <div>
                        <div title={row.recommendation_details} className="chassis-description">{this.getModifiedConfig(row.recommendation_details)}</div>
                    </div>
                ), headerFunction: col => (
                    <div className="chassis-header">
                        {col.title}
                    </div>
                )
            }),
            new P3ColumnData(
                "Disk type and Quantity per NVR",
                "drive_description"
            ).mixin({
                allowSort: false, allowFilter: false, width: "17.66%", labelFunction: (row, col, index) => (
                    <div>
                        <div title={row.drive_description} className="chassis-description">{this.getModifiedDescription(row.drive_description)}</div>
                    </div>
                ), headerFunction: col => (
                    <div className="chassis-header">
                        {col.title}
                    </div>
                )
            }),
            new P3ColumnData(
                "Number of NVRs",
                "num_nodes"
            ).mixin({
                allowSort: false, allowFilter: false, width: "10.66%", labelFunction: (row, col, index) => (
                    <div className="chassis-row">
                        {row.num_nodes}
                    </div>
                )
            }),
            new P3ColumnData(
                "Raw Capacity",
                "total_capacity_tb"
            ).mixin({
                allowSort: false, allowFilter: false, width: "16.66%", labelFunction: (row, col, index) => (
                    <div className="chassis-row">
                        {row.total_capacity_tb} TB
                    </div>
                )
            }),
            new P3ColumnData(
                "Usable Capacity",
                "actual_capacity_tb"
            ).mixin({
                allowSort: false, allowFilter: false, width: "16.66%", labelFunction: (row, col, index) => (
                    <div className="chassis-row">
                        {Math.round(row.actual_capacity_tb*row.num_nodes)} TB
                    </div>
                )
            })
        ];
    }

    componentDidUpdate(prevProps, _prevState) {
        if(prevProps.selectedNvr !== this.props.selectedNvr){
            this.setState({selectedNvr:this.props.selectedNvr})
        }
        if(prevProps.nvr !== this.props.nvr){
            this.setState({nvr:this.props.nvr}, ()=>this.setNvrItems(false))
        }
        if(prevProps.networkTypes !== this.props.networkTypes){
            this.setState({networkTypes:this.props.networkTypes})
        }
        if(prevProps.selectedNetwork !== this.props.selectedNetwork){
            this.setState({selectedNetwork:this.props.selectedNetwork})
        }
        if(prevProps.nvrIndex !== this.props.nvrIndex){
            this.setState({nvrIndex:this.props.nvrIndex})
        }
        if(prevProps.smartNvrIndex !== this.props.smartNvrIndex){
            this.setState({smartNvrIndex:this.props.smartNvrIndex})
        }
    }

    onItemChange = (row, col, index, ind) => {
        ind = 1;
        if (row && !row.isActive) {
            this.selectedItem = row;
            let selectedNetwork = row && row.nics && row.nics.length?row.nics[0].display:""
            this.props.updateValue({"networkTypes":row.nics, selectedNetwork, [ind === 1?"nvrIndex":"smartNvrIndex"]:index,["smartNvrIndex"]:index});
            this.onDeviceChange(row, index, ind === 0);
        }
    }
    onCompChange = (value, name) => {
        this.props.updateValue({ [name]: value });
        //this.setState({ [name]: value });
    }

    setNvr = (ind) => {
        let networkIndex = ind === 0 ? this.state.nvrIndex : this.state.smartNvrIndex;
        let networkTypes = this.state.nvr[ind].children[networkIndex].nics;
        let selectedNetwork = (networkTypes && networkTypes.length)?networkTypes[0].display:this.state.selectedNetwork;
        this.props.updateValue({selectedNvr: ind, networkTypes, selectedNetwork});
    }

    showDesc = (index) =>{
        let description = this.state.nvr[index].product_class_description || ""
        this.props.openAlert(description,P3StatusBarStatus.INFO);
    }

    render() {
        let isNetworkDisabled = !this.state.networkTypes || !this.state.networkTypes.length;
        return (
            <ScrollArea
                    ref={input => (this.scrollArea = input)}
                    className="p3-scrollbar-area"
                    contentClassName={
                        " p3-scrollbar-content"
                    }
                    smoothScrolling={true}
                >
            <div className="w-100 h-100 col">
                {this.state.nvr.filter(el=>el.product_class==="nvr").map((item,index) =>
                    <div key={index}>
                        <div className="col-12 p3-compute">
                            <div className="row  pl-0 pt-4 pb-4 font-weight-bold">
                                Standard NVR 
                            </div>
                        </div>
                        <div className={"p3-datagrid-main-container chassis-grid " + (this.state.selectedNvr === index ? "div-enable" : "div-enable")}>
                            <P3DataGrid
                                usePagination={false}
                                columns={this.getCols(item.product_class)}
                                selectable
                                itemRefrenceKey="recommendation_type"
                                tableHeaderClassName="w-100"
                                rowClassName="chassis-row"
                                allowColumnResize={false}
                                onSelect={(row,col,ind)=>this.onItemChange(row,col,ind, index)}
                                dataProvider={item.children}
                            />
                        </div>
                    </div>)}
            </div>
            </ScrollArea>
        );
    }
}

export class P3ColumnData {
    constructor(
        title,
        dataField,
        labelFunction = null,
        headerFunction = null,
        width = null,
        mixin = null
    ) {
        this.title = title;
        this.dataField = dataField;
        this.width = width;
        this.labelFunction = labelFunction;
        this.headerFunction = headerFunction;
        this.allowFilter = true;
        this.filterFunction = null;
        this.filterText = "";
        this.allowSort = true;
        this.sortCompareFunction = null;
        this.sortFlipper = 0;
        this.showTooltip = false;
        this.allowCopyToClipboard = false;

        if (mixin !== null) {
            this.mixin(mixin);
        }
    }

    mixin = props => {
        if (props !== null) {
            let keys = Object.keys(props);
            keys.forEach(key => {
                this[key] = props[key];
            });
        }
        return this;
    };
}
export default ChassisConfiguration
