import React from "react";
import './App.css';
import MainView from './sizer/main-view';
import 'bootstrap/dist/css/bootstrap.css';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    createHttpLink
} from "@apollo/client";
// import { P3LoadingBar, P3StatusBarStatus } from "./components/status-bar/status-bar";

const httpLink = createHttpLink({ uri: '/v1/graphql' });

const client = new ApolloClient({
    cache: new InMemoryCache({ addTypename: false }),
    link: httpLink,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
    }
});

function App() {
    // const [isLoading, setLoading] = useState(true);

    // if (isLoading) {
    //     return (
    //         <P3LoadingBar
    //             statusIcon={P3StatusBarStatus.LOADING}
    //             message="Loading"
    //         />
    //     )
    // }
    return (
        <>
            {/* {!isLoading && <div className="bg-header"><div className="container header-space">
                <div className="quantum-logo" />
                <div className="profile-menu">
                    <label type="button" className="dropdown-toggle pt-2 pr-3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {username}
                    </label>
                </div>
            </div>
            </div>} */}
            <div className="container App">
                <ApolloProvider client={client}>
                    <MainView />
                </ApolloProvider>
            </div>
        </>
    );
}

export default App;
