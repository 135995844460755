import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { P3TextBox } from "../../components/form/p3-textbox";
import { Accordion } from "../../components/p3-accordion";
import { ScrollArea } from "../../components/p3-scrollBar";
import { LOAD_DATA } from "../../graphql/queries";
// import { useKeycloak } from "@react-keycloak/web";
import { CameraPage } from "./camera-page";
import "./sizer-style.scss";

const MAX_COUNT = 20;
const getCompValues=(bandWidth,diskSpace)=>{
    let isGB = diskSpace > 0 && diskSpace < 1;
    let isKB = bandWidth > 0 && bandWidth < 1;
	let diskLabel = isGB?"(GB)":"(TB)";
    let diskValue = isGB?(diskSpace*1000):diskSpace;
    let bandLabel = isKB?"(Kbit/s)":"(Mbit/s)";
    let bandValue = isKB?(bandWidth*1000):bandWidth;
    return {diskLabel,diskValue,bandLabel,bandValue};
}
export class CameraSizer extends React.Component {
    static propTypes = {
        onClose: PropTypes.func,
        onStatusAlert: PropTypes.func
    };
    constructor(props) {
        super(props);
        this.state = {
            isProgress: false,
            isAlertPopup: false,
            resolutions: this.props.resolutions,
            defaultActivePanelIndices: this.props.defaultActivePanelIndices,
            cameras: this.props.cameras
        };
        this.customerPhoneErrorMessage = {
            required: "Fields are required",
            minLength: "Fields should be between 2 and 50 characters",
            maxLength: "Fields should be between 2 and 50 characters"
        }
    }

    componentDidUpdate(prevProps, _prevState) {
        if (this.props.defaultActivePanelIndices !== prevProps.defaultActivePanelIndices) {
            this.setState({
                defaultActivePanelIndices: this.props.defaultActivePanelIndices
            });
        }
    }

    addPage = () => {
        this.props.addPage();
        setTimeout(()=>this.scrollArea && this.scrollArea.scrollArea.scrollBottom(),100);
    }

    sum = (arr, param) => {
        return arr?arr.reduce((a, b) => a + ((b[param]) || 0), 0):0
    }

    afterTransition = ({ activePanelIndices }) => {
        this.props.changeActiveIndex(activePanelIndices);
    }

    afterChange = () => {
        this.scrollArea && this.scrollArea.scrollArea.refresh();
    };

    render() {
        let addButtonClass = this.state.cameras.length < MAX_COUNT?"":"btn-disable";
        let computeValues = getCompValues(this.props.bandWidth, this.props.diskSpace);
        return (
            <div className="w-100 h-100 cam-size-cont">
                <ScrollArea
                    ref={input => (this.scrollArea = input)}
                    className="p3-scrollbar-area"
                    contentClassName={
                        " p3-scrollbar-content"
                    }
                    smoothScrolling={true}
                >
                    <Accordion
                        dataSource={this.state.cameras}
                        titleField={"cameras"}
                        contentField={"bitRate"}
                        isValid={this.props.isValid}
                        titleRenderer={CamTitleRenderer}
                        contentRenderer={CamContentRenderer} // custom renderer for content
                        isMultiOpen={true}
                        // keycloak={this.props.keycloak}
                        modifyPage={this.props.modifyPage}
                        data={this.props.data}
                        onTextInputChange={this.onTextInputChange}
                        onDelete={this.props.onDelete}
                        defaultActivePanelIndices={this.state.defaultActivePanelIndices}
                        afterTransition={this.afterTransition}
                        afterChange={this.afterChange}
                        errorMessage={this.customerPhoneErrorMessage}
                        collapseIcon="p3-right-arrow"
                        expandIcon="p3-down-arrow"
                        className="cam-sizer-accordion"
                    />
                </ScrollArea>
                <div className="add-btn">
                    <div className={"add-button "+addButtonClass} title={"Maximum Camera Set count is "+MAX_COUNT} onClick={this.addPage}>+ Add Camera Set</div>
                </div>
                <div className="cam-total">
                    <label className="total-label">Total</label>
                    <div className="row col-5">
                        <div className="row col-6 title-cont">
                            <label className="title-label-details">{"Total Bandwidth Required "+computeValues.bandLabel}</label>
                            <label className="content-label-details content-highlight">{Math.round(computeValues.bandValue) || 0}</label>
                        </div>
                        <div className="row col-6 title-cont">
                            <label className="title-label-details">{"Total Storage Required "+computeValues.diskLabel}</label>
                            <label className="content-label-details content-highlight">{Math.round(computeValues.diskValue) || 0}</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const CamTitleRenderer = (prop, index) => {
    let props = prop ? prop.child : null;
    let dataAutoId = `vSphere-cluster-title-${index}`;
    if (props) {

        return (
            <CameraTitle
                // keycloak={prop.keycloak}
                id={dataAutoId} onDelete={()=>prop.onDelete(index)} number_of_cameras={props.number_of_cameras}
                customerPhoneErrorMessage={prop.errorMessage} index={index} prop={props} isValid={prop.isValid} modify={prop.modifyPage}
                onTextInputChange={(data, type, e) => { e && String(data[e.target.name]) !== String(props.number_of_cameras) && prop.modifyPage(e.target.name, data[e.target.name], index, data) }} />
        );
    }
};

const CamContentRenderer = ({ child, props, index }) => {
    return (
        <CameraPage modifyPage={props.modifyPage}
            data={props.data} onKeyPress={onlyNumberKey}
            {...child} index={index} />
    );
};

const onlyNumberKey = (evt)=> {
    // Only ASCII character in that range allowed
    var ASCIICode = (evt.which) ? evt.which : evt.keyCode
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)){
        evt.preventDefault();
    }
}

export function CameraTitle(props) {
    // this.props.setCamInput({number_of_cameras : 3,resolution:"200x300",compression:"test",compression_ratio:45, frames_per_second:25,
	// 				retention_days:60, scene_activity:25, recording_profile:56});
    // const { keycloak } = useKeycloak();
    const [isError,setIsError] = useState(false);
    const {number_of_cameras, resolution, compression, compression_ratio, frames_per_second, retention_days, scene_activity, recording_profile} = props.prop;
	const {data, error} = useQuery(LOAD_DATA,{
        context: {
			headers: {
				// "authorization":"Bearer "+keycloak.token,
				"Content-Type": "application/json"
			}
		},
		variables: {
			number_of_cameras: Number(number_of_cameras), resolution, compression, frames_per_second: Number(frames_per_second), retention_days: Number(retention_days),
                compression_ratio:Number(compression_ratio), recording_profile: Number(recording_profile), scene_activity: Number(scene_activity) }
	});
    const sizeData = data || {};
    const sizeData1 = data ?data.compute_bandwidth_storage : {};
    useEffect(() => {
        let data={};
        if(error !== undefined){
            data.bandwidth = 0;
            data.disk_space = 0;
            data.bit_rate = 0;
            data.frame_size = 0;
            props.modify("disk",data,props.index);
            setIsError(true);
        }
        if(sizeData.hasOwnProperty("compute_bandwidth_storage")){
            data = JSON.parse(JSON.stringify(sizeData.compute_bandwidth_storage));
            //data.bandwidth = Math.round(data.bandwidth);
            //data.disk_space = Math.round(data.disk_space);
            props.modify("disk",data,props.index);
            setIsError(false);
        }
	}, [data, error]);
    let computeValues = getCompValues(sizeData1.bandwidth, sizeData1.disk_space);
    return (
        <div className={"camera-style "+(isError?"error-cont":"")} id={props.id}>
            <div className="col col-4">
                <label className="col-9 cont-label float-left">No of Camera(s)</label>
                <P3TextBox
                    data-auto-id="number_of_cameras"
                    fieldName="number_of_cameras"
                    className="col-9 customer-input float-left"
                    onKeyPress={onlyNumberKey}
                    value={props.number_of_cameras}
                    disabled={props.disabled}
                    minLength={1}
                    maxLength={50}
                    errorMessages={props.customerPhoneErrorMessage}
                    onNotify={props.onTextInputChange}
                />

            </div>
            <div className="row col-2 title-cont">
                <label className="title-label">Camera bitrate (Kbit/s)</label>
                <label className="content-label content-normal">{Math.round(sizeData1.bit_rate) || 0}</label>
            </div>
            <div className="row col-2 title-cont">
                <label className="title-label">Frame Size (KB)</label>
                <label className="content-label content-normal">{Math.round(sizeData1.frame_size) || 0}</label>
            </div>
            <div className="row col-2 title-cont">
                <label className="title-label">{"Bandwidth Required "+computeValues.bandLabel}</label>
                <label className="content-label content-highlight" title={Math.round(computeValues.bandValue) || 0}>{Math.round(computeValues.bandValue) || 0}</label>
            </div>
            <div className="row col-3 title-cont">
                <label className="title-label">{"Storage Required "+computeValues.diskLabel}</label>
                <label className="content-label content-highlight">{Math.round(computeValues.diskValue) || 0}</label>
            </div>
            <div className="close-btn" onClick={props.onDelete} />
        </div>
    )
}
export default CameraSizer
