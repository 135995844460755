import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { ScrollArea } from "../../components/p3-scrollBar";
import { P3LoadingBar, P3StatusBarStatus } from "../../components/status-bar/status-bar";
//  import { P3TextBox } from "../../components/form/p3-textbox";
import { GET_QUOTE } from "../../graphql/queries";
import "./sizer-style.scss";
// import { useKeycloak } from "@react-keycloak/web";
const SITE_DESCRIPTION = "Site name should be unique and should not create using existing site names";
 export function Summary(props) {
    // const { keycloak } = useKeycloak();
    const [summaryData, setSummaryData] = useState([]);
    const { data, loading, error } = useQuery(GET_QUOTE, {
        context: {
            headers: {
                // "authorization": "Bearer " + keycloak.token,
                "Content-Type": "application/json"
            },
        },
        variables: {
			quoteData: props.quoteInput
		}
    });

    // const getSummary = (quote) =>{
    //     getQuote({ variables: { quoteData: quote } });
    // }

    const getQuantity = (desc) =>{
        let quantArray = desc.split(",");
        let quantity = quantArray[2];
        return quantity.slice(2,-2);
    }

    const getDriveType = (desc) =>{
        let driveType = desc.split(",").slice(-2);
        let finalDriveType = driveType[0].split(" ")[1]+ driveType[1]+" HDD"; 
        return finalDriveType;
    }

    const getModifiedDescription=(desc)=>{
        let arr = desc.split(",");
        arr.splice(2,1);
        arr[3]=arr[3].toUpperCase();
        let nvrName = arr[1].split("-");
        return "Quantum"+ nvrName[0] + (arr[0].includes("SMART")?"-SNVR,":"-NVR,") + arr[2] + ", " + arr[3];
    }
    useEffect(() => {
        let selectedNvr = props.selectedNvr;
        if(props.nvr.length==1){
            selectedNvr = 0;
        }
        let nvr = props.nvr[selectedNvr].children[(selectedNvr === 0 ? props.smartNvrIndex : props.nvrIndex)];
        if (data && data.get_quote) {
            let newSummary = [];
            for (let i=0;i<data.get_quote.length;i++){
                const item = data.get_quote[i];
                newSummary.push({
                    "key": "NVR Type",
                    "value": item.item==="NVR"?"Standard NVR":item.item
                });
                newSummary.push({
                    "key": "Model",
                    "value": item.model
                });
                newSummary.push({
                    "key": "Configuration",
                    "value": getModifiedDescription(item.description),
                });
                newSummary.push({
                    "key": "Configuration SKU",
                    "value": item.sku
                });
                newSummary.push({
                    "key": "Drive Type",
                    "value": getDriveType(nvr.drive_description)
                });
                newSummary.push({
                    "key": "Drive Type SKU",
                    "value": item.drive_code
                });
                newSummary.push({
                    "key": "Drive Quantity per NVR",
                    "value": getQuantity(nvr.drive_description)
                });
                newSummary.push({
                    "key": "Number of NVRs",
                    "value": item.quantity
                });
                newSummary.push({
                    "key": "Total Raw Capacity",
                    "value": nvr.total_capacity_tb + " TB"
                });
                newSummary.push({
                    "key": "Total Usable Capacity",
                    "value": Math.round(nvr.actual_capacity_tb*item.quantity) + " TB"
                });
            }
            setSummaryData(newSummary);
            props.updateValue({summaryData:newSummary});
        }
    }, [data]);
    useEffect(() => {
        if (error) {
            props.summaryError(error);
        }
    }, [error]);

    if(loading)return (<P3LoadingBar
                            statusIcon={P3StatusBarStatus.LOADING}
                            message="Loading"
                        />)
    return (
        <SummaryPage {...props} summaryData={summaryData}/>
    )
}

export class SummaryPage extends React.Component {
    static propTypes = {
        onClose: PropTypes.func,
        onStatusAlert: PropTypes.func
    };
    constructor(props) {
        super(props);
        this.state = {
            isProgress: false,
            isAlertPopup: false,
            summaryData: []
        };
        this.customerPhoneErrorMessage = {
            required: "Fields are required",
            minLength: "Fields should be between 2 and 50 characters",
            maxLength: "Fields should be between 2 and 50 characters"
        }
    }

    componentDidUpdate(prevProps, _prevState) {
        if(prevProps.summaryData !== this.props.summaryData){
            this.setState({summaryData:this.props.summaryData})
        }
        // if(prevProps.projectName !== this.props.projectName){
        //     this.setState({projectName:this.props.projectName})
        // }
    }

    onTextInputChange = (data, type, e) => {
        if (e) {
            this.props.updateValue({ [e.target.name]: e.target.value });
        }
    };

    // onSubmit = () =>{
    //     this.props.submitProject(this.props.getSummary);
    // }

    openDescription = () =>{
        this.props.openAlert(SITE_DESCRIPTION, P3StatusBarStatus.INFO);
    }

    render() {

        return (
            <ScrollArea
                    ref={input => (this.scrollArea = input)}
                    className="p3-scrollbar-area"
                    contentClassName={
                        " p3-scrollbar-content"
                    }
                    smoothScrolling={true}
                >
            <div className="w-100 h-100 col">
                {/* <div className="col col-12 h-100"> */}
                    {/* {!this.props.isProjectSubmit && <div className="col-12 pb-4">
                        <div className="row justify-content-center">
                            <label className="pl-0 pt-1 font-weight-bold">Site Name</label>
                            <P3InfoIcon className="pl-2 info-icon" onClick={this.openDescription}/>
                        </div>
                        <div className="site-name">
                            <P3TextBox
                                data-auto-id="customerName"
                                fieldName="projectName"
                                className="col-3 customer-input"
                                value={this.state.projectName}
                                minLength={2}
                                maxLength={50}
                                errorMessages={this.customerPhoneErrorMessage}
                                onNotify={this.onTextInputChange}
                                />
                             <button className="btn p3-btn " disabled={this.state.projectName.trim() === ""} onClick={this.onSubmit}>Get Summary</button>
                        </div>

                        

                    </div>
                    }
                    </div> */}
                    <div className="summary-grid">
                        {/* <label className="col-12 chassis-row font-weight-bold pl-0">{this.state.projectName}</label> */}
                        {/* <label className="col-12 config-label pl-0">Quote:</label> */}
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    {this.state.summaryData.map(row => (
                                        <tr key={row.key}>
                                        <th class="text-left" scope="col">
                                            { row.key }
                                        </th>
                                        <td>
                                            { row.value }
                                        </td>
                                    </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                {/* </div> */}
            </div>
            </ScrollArea>
        );
    }
}

export default Summary
