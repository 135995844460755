import PropTypes from "prop-types";
import React from "react";
import { P3TextBox } from "../../components/form/p3-textbox";
import { SelectDropdown } from "../main-view";
import "./sizer-style.scss";
export class CameraPage extends React.Component {
    static propTypes = {
        onClose: PropTypes.func,
        onStatusAlert: PropTypes.func
    };
    // static defaultProps = {
    //     resolutions : []
    // }
    constructor(props) {
        super(props);
        this.state = {
            ...this.props,
            isProgress: false,
            isAlertPopup: false,
        };
        this.customerPhoneErrorMessage = {
            required: "Fields are required",
            minLength: "Fields should be between 2 and 50 characters",
            maxLength: "Fields should be between 2 and 50 characters"
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        //if (nextProps.total !== prevState.total) {
        return ({ ...nextProps }) // <- this is setState equivalent
        //}
        //return null
    }

    onTextInputChange = (data, type, e) => {
        if (e && String(data[e.target.name]) !== String(this.state[e.target.name])) {
            this.setState({ ...data });
            this.props.modifyPage(e.target.name, data[e.target.name], this.props.index, data);
        }
    }

    onSetCustomized = e => {
        this.setState({ isCustomized: !this.state.isCustomized });
    }

    onChange = (value, name) => {
        this.props.modifyPage(name, value, this.props.index);
    }

    onDelete = () => {
        this.props.onDelete(this.props.index);
    }

    render() {
        let data = this.props.data;
        let resolutions = (data && data.camera_resolutions) ? data.camera_resolutions : [];
        let compressions = (data && data.compressions) ? data.compressions : [];
        let scenes = (data && data.scene_activities) ? data.scene_activities : [];
        let compressionRatios = (data && data.compression_ratios) ? data.compression_ratios : [];
        let recordingProfiles = (data && data.recording_profiles) ? data.recording_profiles : [];
        compressions=compressions.filter(el=>el.name!=="MPEG4");
        const {
            frames_per_second,
            retention_days
        } = this.state;
        return (
            <div className="camera-size-container">
                <div className="row camera-style">
                    <div className="col col-3 title-cont">
                        <label className="col-9 cont-label">Resolution</label>
                        <SelectDropdown className="dropdown" dataProvider={resolutions} valueField="name" name="resolution"
                            labelField="name" selectedValue={this.state.resolution} onChange={this.onChange} />
                    </div>
                    <div className="col col-3 title-cont">
                        <label className="col-9 cont-label">Compression</label>
                        <SelectDropdown className="dropdown" dataProvider={compressions} valueField="name" name="compression"
                            labelField="name" selectedValue={this.state.compression} onChange={this.onChange} />
                    </div>
                    <div className="col col-3 title-cont">
                        <label className="col-9 cont-label">Frames per second</label>
                        <P3TextBox
                            data-auto-id="frames_per_second"
                            fieldName="frames_per_second"
                            className="col-9 customer-input"
                            value={frames_per_second}
                            {...this.props}
                            minLength={2}
                            maxLength={50}
                            errorMessages={this.customerPhoneErrorMessage}
                            onNotify={this.onTextInputChange}
                        />
                    </div>
                    <div className="col col-3 title-cont">
                        <label className="col-9 cont-label">Retention Days</label>

                        <P3TextBox
                            data-auto-id="retention_days"
                            fieldName="retention_days"
                            className="col-9 customer-input"
                            {...this.props}
                            value={retention_days}
                            minLength={2}
                            maxLength={50}
                            errorMessages={this.customerPhoneErrorMessage}
                            onNotify={this.onTextInputChange}
                        />
                    </div>
                </div>
                <div className="row camera-style">
                    <div className="col col-3 title-cont">
                        <label className="col-9 cont-label">Scene Activity</label>
                        <SelectDropdown className="dropdown" dataProvider={scenes} valueField="value" name="scene_activity"
                            labelField="name" selectedValue={this.state.scene_activity} onChange={this.onChange} />
                    </div>
                    <div className="col col-3 title-cont">
                        <label className="col-9 cont-label">Compression Ratio</label>
                        <SelectDropdown className="dropdown" dataProvider={compressionRatios} valueField="value" name="compression_ratio"
                            labelField="name" selectedValue={this.state.compression_ratio} onChange={this.onChange} />
                    </div>
                    <div className="col col-3 title-cont">
                        <label className="col-9 cont-label">Recording Schedule</label>
                        <SelectDropdown className="dropdown" dataProvider={recordingProfiles} valueField="value" name="recording_profile"
                            labelField="name" selectedValue={this.state.recording_profile} onChange={this.onChange} />
                    </div>
                </div>
            </div>
        );
    }
}
export default CameraPage